import logo from './logo.svg';
import './App.css';
import { Suspense, lazy } from 'react';


const MainPage = lazy( () => import("./page/main"))

function App() {
  return (
    <Suspense fallback={<div></div>}>
      <MainPage/>
    </Suspense>
  );
}

export default App;
